<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2021-05-07 15:57 -->
<template>
  <div class="table-responsive">
    <table class="table table-hover table-bordered table-sm">
      <thead>
        <tr>
          <th>#</th>
          <th>NOMBRES Y APELLIDOS DEL POSTULANTE</th>
          <th>DNI</th>
          <th>CATEGORIA</th>
          <th>ORIGEN</th>
          <th>MONTO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list"
          :key="i"
          :class="{ 'table-warning': l.client_id == $route.query.client_id }">
          <td class="text-right">{{ i + 1 }}</td>
          <td>&nbsp;{{ l.client_name }}</td>
          <td>{{ l.client_idcard }}</td>
          <td>{{ l.product_code }}</td>
          <td :title="l.origin_comission | money">{{ l.origin_slug }}</td>
          <td class="text-right">{{ l.value | money }}</td>
          <td>
            <button
              class="btn btn-sm btn-light-d-print-none"
              v-show="l.observation"
              data-container="body"
              data-toggle="popover"
              data-placement="left"
              data-trigger="focus"
              :data-content="l.observation"
            >
              <i class="fas fa-comments"></i>
            </button>
            <button
              class="btn btn-sm btn-light d-print-none"
              @click="
                $refs.elAddSaleModal.show();
                $refs.elSaleForm.setValue(l);
              "
              :disabled="disabled"
            >
              <i class="fas fa-edit"></i>
            </button>

            <button
              class="btn btn-sm btn-light d-print-none"
              @click="preEliminar(l.sale_id)"
              :disabled="disabled"
            >
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3"></td>
          <th class="text-center" colspan="2">TOTAL INGRESOS</th>
          <th class="text-right">{{ total | money }}</th>
        </tr>
      </tfoot>
    </table>
    <button
      class="btn btn-primary d-print-none"
      :disabled="disabled"
      @click="
        showAddSaleDialog();
        $refs.elSaleForm.reset();
      "
    >
      Agregar
    </button>

    <app-modal-basic ref="elAddSaleModal">
      <SaleForm
        ref="elSaleForm"
        :cashRegisterId="cashRegisterId"
        @submitted="
          $refs.elAddSaleModal.hide();
          $emit('change');
        "
      ></SaleForm>
    </app-modal-basic>
  </div>
</template>
<script>
import { StoreService } from "src/lt/store-module";
import SaleForm from "./SaleForm.vue";
import $ from "jquery";

export default {
  components: {
    SaleForm
  },

  // directives
  // filters

  props: {
    cashRegisterId: {
      required: true
    },
    disabled: {
      default: false
    }
  },

  data: () => ({
    list: []
  }),

  computed: {
    total() {
      let total = 0;
      if (this.list.map((x) => x.value)[0] > 0) {
        total = this.list
          .map((x) => parseFloat(x.value))
          .reduce((p, c) => p + c, 0);
      }
      return total;
    }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    preEliminar(saleId) {
      this.$options.id = saleId;
      if (confirm("Eliminar la venta?")) {
        StoreService.deleteSale(saleId, "Eliminacion").then(() => {
          this.$emit("change");
        });
      }
    },
    showAddSaleDialog() {
      this.$refs.elAddSaleModal.show();
    },
    async getList() {
      let result = await StoreService.getProductSales({
        cash_register_id: this.cashRegisterId,
        per_page: 100000
      });
      this.list = result.data;
      setTimeout(() => {
        $('[data-toggle="popover"]').popover();
      }, 200);
    }
  }
};
</script>

<style scoped></style>
