<template>
  <table class="table table-bordered table-sm">
    <thead>
      <slot name="thead"></slot>
    </thead>
    <tbody>
      <tr v-for="(l, i) in list" :key="i">
        <td class="text-right">{{ i + 1 }}</td>
        <td class="w-100">{{ l.observation }}</td>
        <td class="text-right text-nowrap">{{ l.quantity | money }}</td>
        <td v-if="!disabled" class="p-0 d-flex">
          <template name="actions" v-bind:item="l"></template>
          <button
            class="btn btn-light btn-sm d-print-none"
            @click="$emit('edit-item', l)"
          >
            <i class="fas fa-edit"></i>
          </button>
          <button
            class="btn btn-light btn-sm d-print-none"
            @click="$emit('delete-item', l.id)"
          >
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      <tr>
        <td></td>
        <th>TOTAL</th>
        <th class="text-right text-nowrap">{{ total | money }}</th>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    list: {
      default: () => []
    },
    disabled: {
      default: false
    }
  },

  data: () => ({
    //
  }),

  computed: {
    total() {
      return this.list
        .map((x) => parseFloat(x.quantity))
        .reduce((p, c) => p + c, 0);
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style scoped></style>
