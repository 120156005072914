<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Razón *</label>
      <SelectReason v-model="reason_id" ref="elSelectReason"></SelectReason>
    </div>
    <div class="form-group">
      <label for>Medio de pago *</label>
      <app-select-pay-method
        v-model="payment_method_id"
        ref="selectPayMethod"
      ></app-select-pay-method>
    </div>

    <div class="form-group">
      <label for>Monto *</label>
      <app-input-amount v-model="quantity"></app-input-amount>
    </div>

    <div class="form-group">
      <label for>Observación</label>
      <app-textarea v-model="observation" :required="true"></app-textarea>
    </div>

    <div class="form-group">
      <app-button-submit
        :loading="sending"
        :disabled="!!errors"
        v-show="!disabled"
      ></app-button-submit>
      <!-- <button class="btn btn-primary" type="submit" :disabled="sending"> -->
      <!--   Guardar -->
      <!-- </button> -->
    </div>

    <!-- <div class="form-group"> -->
    <!--   <app-alert -->
    <!--     class="alert-success" -->
    <!--     msj="Creado con exito" -->
    <!--     :show.sync="saveSuccess" -->
    <!--   ></app-alert> -->
    <!--   <app-alert class="alert-danger" :msj="err" :show.sync="saveError"> -->
    <!--     <template slot="msj"> -->
    <!--       <strong>Error:</strong> -->
    <!--       {{ err }} -->
    <!--     </template> -->
    <!--   </app-alert> -->
    <!-- </div> -->
  </form>
</template>

<script>
import SelectReason from "../reasons/Select.vue";
import { AdminService } from "../AdminService.js";
import SelectPayMethod from "../../general-module/SelectPayMethod.vue";
import validator from "src/lt/utils/validator";
const formRules = {
  quantity: { presence: { allowEmpty: false } },
  reason_id: { presence: { allowEmpty: false } },
  payment_method_id: { presence: { allowEmpty: false } }
};
export default {
  components: {
    SelectReason,
    "app-select-pay-method": SelectPayMethod
  },
  data: () => ({
    id: null,
    reason_id: null,
    payment_method_id: null,
    quantity: null,
    observation: null,
    sending: false,
    targetUrl: "api/admin/outputs"
    // err: ""
  }),
  props: {
    cashRegisterId: {
      default: null
    },
    disabled: {
      default: false
    }
  },
  computed: {
    errors() {
      return validator(this.$data, formRules);
    }
    // areaId() {
    //   return this.$store.state.areas.areaId;
    // }
  },
  created() {
    if (this.cashRegisterId !== null) {
      this.targetUrl = "api/admin/cash-register/outputs";
    }
  },
  methods: {
    setValue(value) {
      this.id = value.id;
      this.reason_id = value.reason_id;
      this.payment_method_id = value.payment_method_id;
      this.quantity = value.quantity;
      this.observation = value.observation;
      this.$refs.elSelectReason.setValue(value.reason_id);
    },
    reset() {
      this.id = null;
      this.reason_id = null;
      this.payment_method_id = 1;
      this.quantity = null;
      this.observation = null;
      this.sending = false;
      this.$refs.elSelectReason.reset();
    },
    async save() {
      this.sending = true;
      try {
        let result = await AdminService.saveCashRegisterOutput({
          ...this.$data,
          cash_register_id: this.cashRegisterId
        });
        this.$emit("submitted", result);
      } catch (e) {
        //
        if (e.response.data.code == 1) {
          alert(e.response.data.code);
        } else {
          alert("Algo malio sal 🤡");
        }
      }
      this.sending = false;
    }
  }
};
</script>

<style></style>
