<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Fuente de Ingreso *</label>
      <SelectSource
        ref="elSelectSource"
        :disabled="disabled"
        v-model="source_id"
      ></SelectSource>
      <app-form-errors :errors="errors.source_id"></app-form-errors>
    </div>
    <div class="form-group">
      <label for>Medio de pago *</label>
      <app-select-pay-method
        ref="selectPayMethod"
        :disabled="disabled"
        v-model="payment_method_id"
      >
      </app-select-pay-method>
    </div>
    <div class="form-group">
      <label for>Monto *</label>
      <app-input-amount
        v-model="quantity"
        :disabled="disabled"
      ></app-input-amount>
      <!-- <app-input-money -->
      <!--   ref="inputMoney" -->
      <!--   :quantity.sync="quantity" -->
      <!--   :moneyCode.sync="money_code" -->
      <!--   :required="true" -->
      <!-- ></app-input-money> -->
    </div>
    <div class="form-group">
      <label for>Observacion</label>
      <app-textarea v-model="observation"></app-textarea>
    </div>
    <div class="form-group">
      <app-button-submit
        :loading="sending"
        v-show="!disabled"
        :disabled="!!errors"
      >
      </app-button-submit>
    </div>
    <!-- <div class="form-group"> -->
    <!--   <app-alert -->
    <!--     class="alert-success" -->
    <!--     msj="Creado con exito" -->
    <!--     :show.sync="saveSuccess" -->
    <!--   ></app-alert> -->
    <!--   <app-alert class="alert-danger" :msj="err" :show.sync="saveError"> -->
    <!--     <template slot="msj"> <strong>Error: </strong> {{ err }} </template> -->
    <!--   </app-alert> -->
    <!-- </div> -->
  </form>
</template>
<script>
import SelectSource from "../sources/Select.vue";
import { AdminService } from "../AdminService";
import SelectPayMethod from "../../general-module/SelectPayMethod.vue";
import validator from "src/lt/utils/validator";
const formRules = {
  source_id: { presence: { allowEmpty: false } },
  payment_method_id: { presence: { allowEmpty: false } },
  quantity: { presence: { allowEmpty: false } }
  // cash_register
  // observation
};
export default {
  components: {
    SelectSource,
    "app-select-pay-method": SelectPayMethod
  },

  // directives
  // filters

  props: {
    disabled: {
      default: false
    },
    cashRegisterId: {
      required: true
    }
  },

  data: () => ({
    id: null,
    source_id: null,
    payment_method_id: null,
    quantity: null,
    observation: "",
    sending: false
  }),

  computed: {
    errors() {
      return validator(this.$data, formRules);
    }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    setValue(value) {
      this.id = value.id;
      this.payment_method_id = 1;
      this.quantity = value.quantity;
      this.observation = value.observation;
      this.sending = false;
      this.$refs.elSelectSource.setValue(value.source_id);
    },
    reset() {
      this.id = null;
      this.source_id = null;
      this.payment_method_id = 1;
      this.quantity = null;
      this.observation = "";
      this.sending = false;
      this.$refs.elSelectSource.reset();
    },
    async save() {
      try {
        this.sending = true;
        let result = await AdminService.saveCashRegisterInput({
          ...this.$data,
          cash_register_id: this.cashRegisterId
        });
        this.$emit("submitted", result);
      } catch (e) {
        //
        if (e.response.data.code == 1) {
          alert(e.response.data.code);
        } else {
          alert("Algo malio sal 🤡");
        }
      }
      this.sending = false;
    }
  }
};
</script>

<style scoped></style>
