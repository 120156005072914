<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2021-05-07 09:29 -->
<template>
  <div>
    <h4 class="text-center">
      REPORTE DIARIO DE INGRESOS Y EGRESOS {{ box.cashregister.created_at | dateFormat }}
    </h4>
    <div class="row mb-3">
      <div class="col-12">
        <Sales
          @change="getBox()"
          :cashRegisterId="box.id"
          :disabled="disabled"
          ref="elSales"
        ></Sales>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-sm-8">
        <div class="row mb-3">
          <div class="col-12">
            <DiarieTable
              :disabled="disabled"
              @delete-item="eliminarEgreso($event)"
              @edit-item="editarEgreso($event)"
              :list="box.outputs"
            >
              <template slot="thead">
                <tr class="text-center">
                  <th>#</th>
                  <th>EGRESOS</th>
                  <th>MONTO</th>
                </tr>
              </template>
            </DiarieTable>
            <button
              class="btn btn-primary d-print-none"
              :disabled="disabled"
              @click="
                $refs.elOutputForm.reset();
                $refs.elModalEgreso.show();
              "
            >
              Agregar Egreso
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <DiarieTable
              :disabled="disabled"
              :list="another_inputs"
              @delete-item="eliminarIngreso($event)"
              @edit-item="editarIngreso($event)"
            >
              <template slot="thead">
                <tr class="text-center">
                  <th>#</th>
                  <th>INGRESOS ADICIONES</th>
                  <th>MONTO</th>
                </tr>
              </template>
            </DiarieTable>
            <button
              :disabled="disabled"
              class="btn btn-primary d-print-none"
              @click="
                $refs.elInputForm.reset();
                $refs.elModalInput.show();
              "
            >
              Agregar Ingreso
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <table class="table table-sm table-bordered">
          <thead>
            <tr class="text-dark">
              <th>INGRESO - EGRESO</th>
              <th>{{ ingresoMenosEgreso | money }}</th>
            </tr>
            <tr>
              <th>INGRESO ADICIONAL</th>
              <th>{{ aditionalInput | money }}</th>
            </tr>
          </thead>
        </table>
        <table class="table table-sm table-bordered text-center">
          <thead>
            <tr class="text-dark">
              <th>V. B.</th>
              <th class="h3" style="vertical-align: middle" rowspan="2">
                {{ (ingresoMenosEgreso + aditionalInput) | money }}
              </th>
            </tr>
            <tr class="text-dark">
              <th>TOTAL</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <a
          class="btn btn-light d-print-none"
          :href="enlaceImpresion"
          target="_blank"
          ><i class="fas fa-print"></i> Imprimir</a
        >
        <!-- <a -->
        <!--   class="btn btn-light d-print-none" -->
        <!--   @click.prevent="printHandler" -->
        <!--   target="_blank" -->
        <!--   ><i class="fas fa-print"></i> Imprimir</a -->
        <!-- > -->
      </div>
    </div>

    <app-modal-basic ref="elModalEgreso">
      <OutputForm
        ref="elOutputForm"
        :cashRegisterId="box.id"
        @submitted="
          $refs.elModalEgreso.hide();
          getBox();
        "
      ></OutputForm>
    </app-modal-basic>

    <app-modal-basic ref="elModalInput">
      <InputForm
        ref="elInputForm"
        :cashRegisterId="box.id"
        @submitted="
          $refs.elModalInput.hide();
          getBox();
        "
      >
      </InputForm>
    </app-modal-basic>
  </div>
</template>
<script>
import { AdminService } from "src/lt/admin-module";
import Sales from "./Sales.vue";
import DiarieTable from "src/lt/admin-module/cashregisters/DiarieTable.vue";
import OutputForm from "src/lt/admin-module/outputs/Form.vue";
import InputForm from "src/lt/admin-module/inputs/Form.vue";
import { hasPermissionTo } from "src/lt/utils/permissionStorage.js";
export default {
  components: {
    Sales,
    DiarieTable,
    OutputForm,
    InputForm
  },

  // directives
  // filters

  props: {
    cashRegisterId: {
      required: true
    },
    disabled: {
      default: false
    }
  },

  data: () => ({
    box: {},
    another_inputs: [],
    canEdit: false
  }),

  computed: {
    // cashRegisterId() {
    //   return this.box.id;
    // },
    enlaceImpresion() {
      return (
        process.env.VUE_APP_API_URL +
        `reports/sancarlos/` +
        `${this.cashRegisterId}/reporte.pdf?print=1`
      );
    },
    ingresoMenosEgreso() {
      if (!(this.box.inputs && this.box.outputs)) {
        return 0;
      }
      // return 0;
      let totalInput = this.box.inputs
        .filter((x) => x.type_id == 5)
        .reduce((p, c) => p + c.quantity, 0);
      let totalOutputs = this.box.outputs.reduce((p, c) => p + c.quantity, 0);
      return totalInput - totalOutputs;
      // let ingreso =
    },
    aditionalInput() {
      return this.another_inputs.reduce((p, c) => p + c.quantity, 0);
    }
  },

  // watch: {},

  mounted() {
    if (this.cashRegisterId !== undefined && this.cashRegisterId !== null) {
      this.getBox(true);
    }
    this.canEdit = hasPermissionTo("mc_edit");
  },

  methods: {
    editarIngreso(ingreso) {
      console.log("aqui bro", ingreso);
      this.$refs.elModalInput.show();
      this.$refs.elInputForm.setValue(ingreso);
    },
    printHandler() {
      window.print();
    },
    eliminarEgreso(id) {
      if (confirm("Eliminar egreso?")) {
        AdminService.deleteCROutput(this.box.id, id);
        this.getBox();
      }
    },
    eliminarIngreso(id) {
      if (confirm("Eliminar ingreso?")) {
        AdminService.deleteInput(id);
        this.getBox();
      }
    },
    editarEgreso(egreso) {
      this.$refs.elModalEgreso.show();
      this.$refs.elOutputForm.setValue(egreso);
    },
    getBox(firstTime = false) {
      if (this.box.id) {
        this.$refs.elSales.getList();
      }
      AdminService.getCashRegister(this.cashRegisterId).then((box) => {
        this.box = box;
        this.another_inputs = box.inputs.filter((x) => x.type_id == 3);
        if (firstTime) {
          setTimeout(() => {
            this.$refs.elSales.getList();
          }, 300);
        }
      });
    }
  }
};
</script>

<style scoped></style>
