<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2021-05-07 18:21 -->
<template>
  <form class="row" @submit.prevent="save" name="simple-form-sale">
    <div class="form-group col-12">
      <label for="">DNI Cliente</label>
      <input
        class="form-control"
        type="text"
        step="1"
        maxlength="8"
        v-model="client_idcard"
        @change="searchClient()"
      />
      <app-form-errors :errors="errors.client_idcard"></app-form-errors>
    </div>
    <div class="col-12" v-show="searching">
      <app-loading>Buscando ...</app-loading>
    </div>
    <div class="form-group col-12">
      <label for="">Nombre Completo</label>
      <input
        class="form-control"
        type="text"
        maxlength="255"
        v-model="client_name"
      />
      <app-form-errors :errors="errors.client_name"></app-form-errors>
    </div>
    <div class="form-group col-12">
      <label for="">Celular</label>
      <input
        class="form-control"
        type="text"
        step="1"
        maxlength="9"
        v-model="client_cell_phone_number"
      />
      <app-form-errors
        :errors="errors.client_cell_phone_number"
      ></app-form-errors>
    </div>
    <div class="form-group col-12">
      <label for="">Categoria</label>
      <SelectProduct
        v-model="product_id"
        ref="elSelectProduct"
        :seeBrand="false"
        @select="unit_price = $event.unit_price"
      ></SelectProduct>
      <app-form-errors :errors="errors.product_id"></app-form-errors>
    </div>
    <div class="form-group col-12">
      <label for="">Precio</label>
      <app-input-amount v-model="unit_price"></app-input-amount>
      <app-form-errors :errors="errors.unit_price"></app-form-errors>
    </div>
    <div class="form-group col-12">
      <div class="row">
        <div class="col">
          <label for="">Origen</label>
          <input
            class="form-control"
            type="text"
            :value="origin"
            @input="origin = $event.target.value.toUpperCase()"
            maxlength="14"
          />
          <app-form-errors :errors="errors.origin"></app-form-errors>
        </div>
        <div class="col">
          <label for="">Comisión</label>
          <app-input-amount v-model="origin_comission"></app-input-amount>
          <app-form-errors :errors="errors.origin_comission"></app-form-errors>
        </div>
      </div>
    </div>
    <div class="col-12 form-group">
      <label>Observación</label>
      <app-textarea v-model="observation"></app-textarea>
    </div>
    <div class="col-12">
      <app-button-loading
        type="submit"
        :disabled="errors"
        :loading="sending"
      ></app-button-loading>
    </div>
  </form>
</template>
<script>
import formMixinInterface from "src/lt/utils/formMixinInterface";
import validator from "src/lt/utils/validator";
import { GeneralService } from "src/lt/general-module/GeneralService";
import { StoreService } from "src/lt/store-module";
import SelectProduct from "src/lt/store-module/products/Select.vue";
const formRules = {
  client_idcard: {
    presence: { allowEmpty: false },
    length: { maximum: 8 },
    numericality: {
      onlyInteger: true
    }
  },
  client_name: {
    presence: { allowEmpty: false },
    length: { maximum: 255 }
  },
  client_cell_phone_number: {
    presence: { allowEmpty: false },
    length: { maximum: 255 },
    numericality: {
      onlyInteger: true
    }
  },
  product_id: {
    presence: { allowEmpty: false }
  },
  origin: {
    presence: { allowEmpty: false }
  }
};
export default {
  mixins: [formMixinInterface],

  components: {
    SelectProduct
  },

  // directives
  // filters

  props: {
    cashRegisterId: {
      required: true
    },
    disabled: {
      default: false
    }
  },

  data: () => ({
    id: null, // product sale id
    client_id: null,
    client_idcard: null,
    client_name: null,
    client_cell_phone_number: null,
    product_id: null,
    unit_price: null,
    origin: null,
    origin_comission: null,
    observation: null,

    searching: false,
    sending: false
  }),

  computed: {
    errors() {
      return validator(this.$data, formRules);
    }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    async searchClient() {
      this.searching = true;
      try {
        let result = await StoreService.getClients({
          search: this.client_idcard
        });
        if (result.data.length > 0) {
          this.client_id = result.data[0].id;
          this.client_name = result.data[0].name;
          this.client_cell_phone_number = result.data[0].cell_phone_number;
        } else {
          let client = await GeneralService.searchDni(this.client_idcard);
          this.client_id = null;
          this.client_name = client.apellidos_nombres;
          // this.client_cell_phone_number = null;
        }
      } catch (e) {
        // alert("algo salio mal :/");
      }
      this.searching = false;
    },
    reset() {
      this.id = null;
      this.client_id = null;
      this.client_idcard = null;
      this.client_name = null;
      this.client_cell_phone_number = null;
      this.product_id = null;
      this.unit_price = null;
      this.origin = null;
      this.origin_comission = null;
      this.observation = null;
      this.searching = false;
      this.sending = false;
      this.$refs.elSelectProduct.reset();
    },
    setValue(productSale) {
      // console.log(productSale);
      this.id = productSale.id;
      this.client_id = productSale.client_id;
      this.client_idcard = productSale.client_idcard;
      this.client_name = productSale.client_name;
      this.client_cell_phone_number = productSale.client_cell_phone_number;
      this.product_id = productSale.product_id;
      this.unit_price = productSale.unit_price;
      this.origin = productSale.origin_slug;
      this.origin_comission = productSale.origin_comission;
      this.observation = productSale.observation;

      this.searching = false;
      this.sending = false;
      this.$refs.elSelectProduct.setValueFromId(this.product_id);
    },
    async save() {
      this.sending = true;
      if (this.client_id == null) {
        let client = await StoreService.saveClient({
          name: this.client_name,
          //idcard_type_id: 1,
          idcard: this.client_idcard,
          cell_phone_number: this.client_cell_phone_number
        });
        this.client_id = client.id;
      }
      try {
        /* creacion */
        if (this.id == null) {
          let sale = await StoreService.saveSale({
            cash_register_id: this.cashRegisterId,
            pay: this.unit_price,
            pay_method: 1,
            client_id: this.client_id,
            products: [
              {
                id: this.product_id,
                quantity: 1,
                unit_price: this.unit_price,
                observation: this.observation
              }
            ],
            origin: this.origin,
            origin_comission: this.origin_comission
            // adds: {
            //   origin: this.origin
            // }
          });
          this.$emit("submitted", sale);
        } else {
          await StoreService.saveProductSale(this.$data);
          this.$emit("submitted");
        }
      } catch (e) {
        alert("Error al registrar venta");
        // console.log(e);
      }
      setTimeout(() => {
        this.sending = false;
      }, 2000);
    }
  }
};
</script>

<style scoped></style>
