// default select array
<template>
  <Multiselect
    v-model="val"
    :options="options"
    placeholder="Selecciona Uno"
    selectLabel="Presiona enter para seleccionar"
    deselectLabel="Presiona enter para deseleccionar"
    :loading="isLoading"
    @search-change="find"
    @open="openHandler"
    :internal-search="false"
  >
    <template slot="singleLabel" slot-scope="props">{{
      props.option.name
    }}</template>
    <template slot="option" slot-scope="props">{{
      props.option.name
    }}</template>
    <span slot="noOptions">Sin resultados</span>
    <span slot="noResult">No hay resultados</span>
  </Multiselect>
</template>

<script>
import { Multiselect } from "vue-multiselect";
import { AdminService } from "../AdminService";
import { debounce } from "lodash";

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      default: undefined
    },
    area_id: {
      default: undefined
    }
  },
  watch: {
    val(to) {
      if (to == null || to.id == undefined) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", to.id);
      }
    },
    area_id() {
      this.getList();
    }
  },
  data() {
    return {
      isLoading: false,
      val: undefined,
      options: []
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    setValue(reasonId) {
      this.isLoading = true;
      AdminService.getReason(reasonId).then((res) => {
        this.val = res;
        this.$emit("input", reasonId);
        this.isLoading = false;
      });
    },
    openHandler() {
      if (this.options.length == 0) {
        this.getList();
      }
    },
    reset() {
      this.getList();
      this.val = null;
    },
    getList() {
      AdminService.getReasons({
        area_id: this.area_id
      }).then((list) => (this.options = list.data));
    },
    find(search) {
      if (search) this.asyncFind(search, this);
    },
    asyncFind: debounce((search, vm) => {
      vm.isLoading = true;
      AdminService.getReasons({
        area_id: vm.area_id,
        search
      }).then((list) => {
        vm.options = list.data;
        vm.isLoading = false;
      });
    }, 350)
  }
};
</script>

<style></style>
