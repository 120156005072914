<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<template>
  <select
    class="custom-select"
    @input="$emit('input', $event.target.value)"
    :value="value"
  >
    <option v-for="(l, i) in methods" :key="i" :value="i">{{ l.value }}</option>
    <!-- <option :value="1">EFECTIVO</option> -->
    <!-- <option :value="2">ELECTRONICO</option> -->
    <!-- <option :value="3">DESCUENTO PLANILLA</option> -->
  </select>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    value: {
      //
    }
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({
      methods: (s) => s.config.payment_method.list
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style scoped></style>
